export default {
  GET_THEMES_AS_ADMIN: 'getThemesAsAdmin',
  GET_USER_THEMES: 'getUserThemes',
  GET_THEME: 'getTheme',
  GET_USER_SELECTED_THEME: 'getUserSelectedTheme',
  LINK_THEME_TO_TENANT: 'linkThemeToTenant',
  LINK_THEME_TO_GROUP: 'linkThemeToGroup',
  UNLINK_THEME_TO_TENANT: 'unlinkThemeToTenant',
  UNLINK_THEME_TO_GROUP: 'unlinkThemeToGroup',
  GET_TENANT_AVAILABLE_THEMES: 'getTenantAvailableThemes',
  SAVE_THEME: 'saveTheme',
  DELETE_THEME: 'deleteTheme',
}
