import AxiosContent from '@/services/api/content/axios_content'

export default {
  getNodeActions: (nodeId) => {
    return AxiosContent.get(`actions/node/${nodeId}`)
  },

  generateDownloadLink: (nodeId, params) => {
    return AxiosContent.get(`actions/generate/download-link/${nodeId}`, { params })
  },

  setCustomIcon: (nodeId, formData) => {
    return AxiosContent.post(`actions/node/custom-icon/${nodeId}`, formData)
  },
}
