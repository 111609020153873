import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'application_instance_title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'access_url',
      type: 'text',
      label: 'Url',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      readonly: false,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'application_id',
      type: 'select',
      label: 'Application',
      items: [],
      itemText: 'title',
      itemValue: 'id',
      required: true,
      hide: false,
      chips: true,
      multiple: false
    }
  ],

  tableHeaders: [
    {text: 'Title', value: 'application_instance_title'},
    {text: 'Url', value: 'access_url'},
    {text: 'Application', value: 'application_key'},
    {text: 'ID', value: 'application_instance_id', sortable: false},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  applications_instances: [],

  selectedApplicationInstance: null
}
