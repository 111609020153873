  export default {
  GET_NODE_ACTIONS: 'getNodeActions',
  UPLOAD_FILE: 'uploadFile',
  PREPARE_FILE: 'prepareFileToContent',
  CREATE_FOLDER: 'createFolder',
  CUSTOM_ACTION: 'customAction',
  DOWNLOAD_FILE: 'downloadFile',
  RENAME: 'rename',
  REMOVE: 'remove',
  SYNC: 'syncFolder',
  MOVE: 'move',
  MOVE_ENTRIES: 'moveEntries',
  COPY: 'copy',
  CREATE_GDOC: 'createGdoc',
  EDIT_GDOC: 'editGdoc',
  FINISH_EDIT_GDOC: 'finishEditGdoc',
  CONVERT_TO_GDOC: 'convertToGDoc',
  GENERATE_DOWNLOAD_LINK: 'generateDownloadLink',
  SET_CUSTOM_ICON: 'setCustomIcon',
}
