import i18n from '@/i18n.js'

export default {
  fileSize: (bytes, si = false, decimalPlaces = 1) => {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    let u = -1
    const r = 10**decimalPlaces

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(decimalPlaces) + ' ' + units[u]
  },

  date: (dateStr) => {
    var date = new Date(dateStr)

    var options = { year: 'numeric', month: 'short', day: 'numeric' }

    return date.toLocaleDateString(i18n.locale, options)
  },

  dateTime: (dateStr) => {
    var date = new Date(dateStr)

    var options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: false }

    return date.toLocaleDateString(i18n.locale, options)
  },

  timestampToDate: (timestamp) => {
    if (!timestamp) return ''

    var utc = new Date(
      timestamp.substr(0, 4),
      timestamp.substr(4, 2),
      timestamp.substr(6, 2),
      timestamp.substr(8, 2),
      timestamp.substr(10, 2),
      timestamp.substr(12, 2)
    )

    var date = new Date(utc)

    var options = { year: 'numeric', month: 'short', day: 'numeric' }

    return date.toLocaleDateString(i18n.locale, options)
  },

  epochTimestampToDate: (timestamp, withTime = false) => {
    if (typeof timestamp === 'number') {
      timestamp = timestamp * 1000
    }
    const date = new Date(timestamp)
    var options = { year: 'numeric', month: 'short', day: 'numeric' }

    var strDate = date.toLocaleDateString(i18n.locale, options)

    if (!withTime) {
      return strDate
    }

    strDate += ' - ' + date.toLocaleTimeString(i18n.locale, {hour: '2-digit', minute:'2-digit', hour12: false})

    return strDate
  }
}
