import api from '@/services/api'
import GroupActions from './actions-types'
import GroupMutations from './mutations-types'

export default {
  [GroupActions.SAVE_GROUP]({ state }) {
    return new Promise((resolve, reject) => {
      if (state.selectedGroup) {
        let data = {}

        data['group_title'] = state.fields.find(f => f.name == 'group_title')['value']
        data['active'] = state.fields.find(f => f.name == 'active')['value']
        data['application_ids'] = state.fields.find(f => f.name == 'application_ids')['value']
        data['application_instance_ids'] = state.fields.find(f => f.name == 'application_instance_ids')['value']
        data['tenant_id']  = state.fields.find(f => f.name == 'tenant_id')['value']

        api.core.group
          .updateGroup(data, state.selectedGroup.user_group_id)
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => reject())

      } else {
        let data = {}

        state.fields.forEach((item) => {
          data[item.name] = item.value
        })

        api.core.group
          .createGroup(data)
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => reject())
      }
    })
  },

  [GroupActions.GET_ALL_GROUPS]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.group
        .getAllGroups(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [GroupActions.GET_GROUPS_BY_COMPANY]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.group
        .getGroupsByCompany(params.companyId, params.options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [GroupActions.HANDLE_APPLICATIONS_ITEMS]({ rootState, commit }, {applicationsIds, applicationsInstanceIds}) {
    const applications = rootState.app.applications
    const applicationInstances = rootState.core.application_instance.applications_instances.items

    let applicationsItems = []
    let applicationsInstanceItems = []

    applications.forEach((application) => {
      if (applicationsIds.indexOf(application.id) > -1) {
        applicationsItems.push(application)
      }
    })

    applicationInstances.forEach((applicationInstance) => {
      if (applicationsInstanceIds.indexOf(applicationInstance.application_instance_id) > -1) {
        applicationsInstanceItems.push(applicationInstance)
      }
    })

    commit(GroupMutations.SET_FIELD_APPLICATIONS, applicationsItems)
    commit(GroupMutations.SET_FIELD_APPLICATION_INSTANCES, applicationsInstanceItems)
  },

  [GroupActions.GET_USER_GROUP]({}, userGroupId) {
    return new Promise((resolve, reject) => {
      api.core.group
        .getUserGroup(userGroupId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [GroupActions.GET_USERS_FROM_GROUP]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.group
        .getUsersFromGroup(params.userGroupId, params.options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [GroupActions.DELETE_USER_FROM_GROUP]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.group
        .deleteUserFromGroup(params.userGroupId, params.userId)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  }
}