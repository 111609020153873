import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
      replicateValToIdx: 1
    },
    {
      name: 'key',
      type: 'slug',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory'),
        v => (v && v.length >= 3) || i18n.t('form_rules.min_length', {name: 'key', min: "3"}),
      ],
      required: true,
      readonly: false,
      replicable: true,
      value: null
    },
    {
      name: 'cnpj',
      type: 'text',
      label: 'CNPJ',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      hide: false,
      value: null,
      mask: "##.###.###/####-##",
    },
    {
      name: 'app_instance_ids',
      type: 'select',
      label: 'Applications Instances',
      items: [],
      itemText: 'application_instance_title',
      itemValue: 'application_instance_id',
      chips: true,
      multiple: true
    },
    {
      name: 'theme',
      type: 'select',
      label: 'Theme',
      items: [],
      required: false,
      hide: false,
      value: null,
      replicateValToIdx: 1,
      itemText: 'theme_title',
      itemValue: 'theme_id',
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    },
  ],

  tableHeaders: [
    {text: 'Key', value: 'key'},
    {text: 'Title', value: 'title'},
    {text: 'Application Instances', value: 'app_instance_ids'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  tenants: [],

  selectedTenant: null,

  inactiveFilterValue: false,

  quotaFields: [
    {
      name: 'service',
      type: 'select',
      label: 'Service',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'Syntethic Media',
          val: 'synthetic_image'
        }
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'type_of_billing',
      type: 'select',
      label: 'Billing type',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'Monthly fee',
          val: 'monthly_fee'
        },
        {
          label: 'Prepaid',
          val: 'prepaid'
        },
        {
          label: 'Postpaid',
          val: 'postpaid'
        },
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'balance_limit',
      type: 'text',
      label: 'Balance limit',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory'),
        v => v <= 0 || i18n.t('form_rules.zero_or_negative')
      ],
      autofocus: false,
      required: false,
      hide: true,
      value: null
    },
    {
      name: 'monthly_credit',
      type: 'text',
      label: 'Monthly credit',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory'),
        v => v >= 0 || i18n.t('form_rules.zero_or_positive')
      ],
      autofocus: false,
      required: false,
      hide: true,
      value: null
    }
  ],

  selectedQuota: null,
}
