import api from '@/services/api'
import ThemeActions from './actions-types'

export default {
  [ThemeActions.GET_THEMES_AS_ADMIN]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .getThemesAsAdmin(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ThemeActions.GET_USER_THEMES]({}) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .getUserThemes()
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ThemeActions.GET_THEME]({}, themeId) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .getTheme(themeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ThemeActions.GET_USER_SELECTED_THEME]({dispatch}, users_id) {
    let selectedThemeId = localStorage.getItem("theme_id_for_user_id_" + users_id) || null

    if (selectedThemeId) {
      return dispatch(ThemeActions.GET_THEME, selectedThemeId)
    }

    return dispatch(ThemeActions.GET_USER_THEMES)
      .then((themes) => {
        if (typeof themes == 'undefined' || themes.length == 0) {
          return null
        }

        const selectedTheme = themes[0]

        localStorage.setItem("theme_id_for_user_id_" + users_id, selectedTheme.theme_id)

        return selectedTheme
      })
  },

  [ThemeActions.SAVE_THEME]({ state }) {
    const data = state.fields.reduce((result, item) => {
      if (item.saveIn == "theme_config") {
        result['theme_config'][item.name] = item.value
      } else {
        result[item.name] = item.value
      }

      return result
    }, {'theme_config': {}})

    data["theme_config"] = JSON.stringify(data["theme_config"])

    return new Promise((resolve, reject) => {
      if (state.selectedTheme) {
        api.core.theme
          .updateTheme(data, state.selectedTheme.theme_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => reject(error))
      } else {
        api.core.theme
          .createTheme(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => reject(error))
      }
    })
  },

  [ThemeActions.DELETE_THEME]({}, themeId) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .deleteTheme(themeId)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => reject(error))
    })
  },

  [ThemeActions.LINK_THEME_TO_TENANT]({ }, {tenantId, themeId}) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .linkThemeToTenant(tenantId, themeId)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => reject(error))
      })
  },

  [ThemeActions.UNLINK_THEME_TO_TENANT]({ }, {tenantId, themeId}) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .unlinkThemeToTenant(tenantId, themeId)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => reject(error))
      })
  },

  [ThemeActions.LINK_THEME_TO_GROUP]({ }, {groupId, themeId}) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .linkThemeToGroup(groupId, themeId)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => reject(error))
      })
  },

  [ThemeActions.UNLINK_THEME_TO_GROUP]({ }, {groupId, themeId}) {
    return new Promise((resolve, reject) => {
      api.core.theme
        .unlinkThemeToGroup(groupId, themeId)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => reject(error))
      })
  },

  [ThemeActions.GET_TENANT_AVAILABLE_THEMES]({ }, tenantId) {
    return new Promise((resolve, reject) => {
      api.core.theme
      .getTenantAvailableThemes(tenantId)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => reject(error))
    })
  },
}
