import AxiosNode from '@/services/api/node/axios_node'

export default {
  getEnablers: (data) => {
    return AxiosNode.get('/enabler', {params: data})
  },

  createEnabler: (data) => {
    return AxiosNode.post('/enabler', data)
  },

  updateEnabler: (data, id) => {
    return AxiosNode.put('/enabler/' + id, data)
  },

  getEnablersAttached: (nodeId, options) => {
    return AxiosNode.get('/enabler/attached/' + nodeId, {params: options})
  },  

  getEnablersByNode: (nodeId, options) => {
    return AxiosNode.get('/enabler/node/' + nodeId, {params: options})
  },  
}